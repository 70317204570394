export default (router, i18n) => {
  const moduleRoutes = import.meta.glob('@modules/**/router.js', {
    eager: true,
    import: 'default',
  });

  for (const moduleRoute in moduleRoutes) {
    router.addRoute('main', moduleRoutes[moduleRoute](i18n));
  }

  return router;
};
