import { randomString } from '@/utils';

export class Step {
  id;

  constructor(data) {
    this.id = data._id;
    this.name = data.name;
    this.type = data.type;
    this.answers = data?.answers;
    this.position = data?.position || { x: 0, y: 0 };
    this.prompts = data.prompts;
    this.messages = this.getMessageList(data || {});
    this.options = data?.options || [];
    this.chatBotId = data?.chatBotId;
    this.nextStep = data?.nextStep;
    this.request = data?.request;
    this.saveAsLabel = data?.saveAsLabel;
    this.clientLabel = data?.clientLabel;
    this.apiEnable = !!data?.request;
  }

  getMessageList({ messages = [], type, instructions }) {
    if (type === 'assistant') {
      return instructions ? [instructions] : [];
    }

    return messages.map(({ formType, ...message }) => {
      if (formType === 'text') {
        let text = message.value?.text;
        if (typeof message.value === 'string') {
          text = message.value;
        }

        return {
          key: randomString(),
          formType,
          ...message,
          value: {
            text,
            html: message.value?.html,
            buttons: message.value?.buttons || [],
          },
        };
      }

      return {
        formType,
        ...message,
      };
    });
  }
}
