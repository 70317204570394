<script setup>
import { useRegisterSW } from 'virtual:pwa-register/vue';

const {
  needRefresh,
  updateServiceWorker,
} = useRegisterSW({
  onNeedRefresh() {
    console.log('New refresh');
  },
  onRegisterError(err) {
    console.log(err);
  },
});
</script>

<template>
  <div
    v-if="needRefresh"
    class="alert toast"
    role="alert"
  >
    <div class="message">
      <span>
        New content available, click on reload button to update.
      </span>
    </div>
    <button
      class="button outline primary"
      @click="updateServiceWorker()"
    >
      Reload
    </button>
  </div>
</template>
