<template>
  <router-view />

  <ReloadPrompt />
</template>

<script>
import ReloadPrompt from '@/components/ReloadPrompt.vue';
import { setI18nLanguage } from '@/i18n';
import { LOCALE_LAGUAGE_CHANGE } from '@/eventTypes';

export default {
  components: {
    ReloadPrompt,
  },
  methods: {
    changeLanguage(lang) {
      setI18nLanguage(lang);
      this.$moment.locale(lang);
      this.emitter.emit(LOCALE_LAGUAGE_CHANGE, lang);
    },
  },
  mounted() {
    const lang = this.$store.getters['shared/getLanguage'];
    this.changeLanguage(lang);
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/main.scss";
</style>
