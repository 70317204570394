export class Assistant {
  id;

  constructor(data) {
    this.id = data._id;
    this.name = data.name;
    this.type = data.type;
    this.position = data?.position || { x: 0, y: 0 };
    this.instructions = data.instructions;
    this.documents = data?.documents || [];
    this.functions = data?.functions || [];
    this.options = data?.options || [];
    this.chatBotId = data?.chatBotId;
    this.transferCampaignId = data?.transferCampaignId;
  }
}
