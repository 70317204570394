import {
  split, ApolloClient, ApolloLink, InMemoryCache,
} from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import errorHandler from '@/utils/error.handler';
import store from '@/store';
import { AUTH_TOKEN, IGNORE_LOADER_QUERY } from '@/constants';

const httpEndpoint = import.meta.env.VITE_GRAPHQL_HTTP || 'http://localhost:4040/api/v1/graphql';
const wsEndpoint = import.meta.env.VITE_GRAPHQL_WS || 'ws://localhost:4040/subscriptions';

// Call this in the Vue app file
export function createProvider() {
  // HTTP connection to the API
  const httpLink = createUploadLink({
    // You should use an absolute URL here
    uri: httpEndpoint,
  });

  const middlewareLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return forward(operation);
  });

  const cache = new InMemoryCache();

  const wsLink = new GraphQLWsLink(createClient({
    url: wsEndpoint,
    connectionParams: () => {
      const token = localStorage.getItem(AUTH_TOKEN);

      return { token };
    },
  }));

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    middlewareLink.concat(httpLink),
  );

  // Create the apollo client
  const apolloClient = new ApolloClient({
    link,
    cache,
    connectToDevTools: !import.meta.env.PROD,
  });

  // Create vue apollo provider
  return createApolloProvider({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'network-only',
      },
    },
    watchLoading(loading, countModifier, query) {
      if (!IGNORE_LOADER_QUERY.includes(query.key)) {
        store.commit('loading/updateCounter', countModifier);
      }
    },
    errorHandler(error) {

      if (error.networkError && error.networkError.statusCode === 401) {
        onLogout(apolloClient);
        window.location.href = import.meta.env.VITE_REDIRECT_URL;
      } else if (!error.message.includes('Invalid Token') && !error.name.includes('TypeError')) {
        errorHandler.logErrors(
          '%cError',
          'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
          error.message
        );
      }
    },
  });
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }

  try {
    await apolloClient.resetStore();
  } catch (e) {

    errorHandler.logErrors('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  try {
    await apolloClient.clearStore();
    localStorage.removeItem('currentCompany');
    localStorage.removeItem('currentCampaign');
    localStorage.removeItem('currentChannel');
    localStorage.removeItem('currentAgent');
  } catch (e) {

    errorHandler.logErrors('%cError on cache reset (logout)', 'color: orange;', e.message);
  } finally {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(AUTH_TOKEN);
    }
  }
}

// Get Token
export function getToken(tokenName = AUTH_TOKEN) {
  return localStorage.getItem(tokenName);
}
